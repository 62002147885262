<template>
  <div>
    <v-btn @click="dialogRejectRequest = true"> Rechazar solicitud </v-btn>

    <!-- Dialog reject request -->
    <DialogBox :model="dialogRejectRequest">
      <template slot="toolbar">
        <span>Rechazar solicitud</span>
      </template>
      <template slot="content">
        <v-form ref="formRejectRequest" v-model="formRejectRequestValid">
          <v-select v-model="rejectRequestParams.reason" :items="reasons" label="Motivo" :rules="formRules.textRules" />
          <v-textarea v-model="rejectRequestParams.comment" label="Comentario" filled rows="3" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogRejectRequest', 'formRejectRequest')">Cerrar</v-btn>
        <v-btn text @click="rejectRequest()">Rechazar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin],
  props: {
    request_data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogRejectRequest: false,
      formRejectRequestValid: false,

      reasons: [
        "OVER_INDEBTEDNESS",
        "PERCEPTION",
        "BAD_BUSINESS",
        "BAD_CREDIT_HISTORY",
        "NEGATIVE_RECOMMENDATION",
        "CHANGE_CONDITIONS",
        "UNREALISTIC_INFORMATION",
        "OTHER"
      ],

      rejectRequestParams: {
        request_id: "",
        reason: "",
        comment: ""
      }
    };
  },
  methods: {
    async rejectRequest() {
      if (this.$refs.formRejectRequest.validate()) {
        this.rejectRequestParams.request_id = this.request_data.id;

        await this.$store.dispatch("requests/rejectRequest", this.rejectRequestParams);

        this.closeDialog("dialogRejectRequest", "formRejectRequest");
      }
    },

    // Close dialog, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.rejectRequestParams = this.$options.data().rejectRequestParams;
      this.dialogRejectRequest = false;
    }
  }
};
</script>
