<template>
  <v-container v-if="!_.isEmpty(requestEditable) && !_.isEmpty(borrower)">
    <!-- Banner header -->
    <Banner
      :title="`Solicitud de ${borrower.first_name} ${borrower.last_name} ${
        requestEditable.migration_id ? `(ID de migración: ${requestEditable.migration_id})` : ''
      }`"
    >
      <template v-slot:actions>
        <v-btn
          v-if="permissionsBannerActions.sendRequest.includes(user.role) && requestEditable.status === 'DRAFT'"
          @click="$store.dispatch('requests/sendRequest', requestEditable.id)"
        >
          Enviar solicitud
        </v-btn>
        <v-btn
          v-if="permissionsBannerActions.approveRequest.includes(user.role) && requestEditable.status === 'REVIEW'"
          @click="approveRequest(requestEditable.id)"
        >
          Aprobar solicitud
        </v-btn>
        <BtnRejectRequest
          v-if="permissionsBannerActions.rejectRequest.includes(user.role) && requestEditable.status === 'REVIEW'"
          :request_data="{ id: requestEditable.id }"
        />
        <v-divider vertical inset class="mx-4" />
        <v-btn
          v-if="permissionsBannerActions.downloadPdf.includes(user.role) && requestEditable.status !== 'DRAFT'"
          small
          @click="getUrlAndOpen(`requests/${requestEditable.id}/request.pdf`)"
        >
          <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="24 " />
        </v-btn>
        <v-btn
          color="red"
          v-if="
            permissionsBannerActions.delete.includes(user.role) &&
            (requestEditable.status === 'DRAFT' || requestEditable.status === 'REVIEW')
          "
          @click="dialogDeleteRequest = true"
          small
        >
          <v-icon color="white" small>delete</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Information about request status -->
    <div>
      <p class="mt-4"><b>Estado:</b> {{ requestEditable.status }}</p>
      <p v-if="requestEditable.project_id" class="mt-4">
        <b>Proyecto: </b>
        <router-link :to="`/proyectos/${requestEditable.project_id}`">{{ requestEditable.project_id }}</router-link>
      </p>
      <p class="mt-4">
        <b>Partner: </b>
        <router-link :to="`/partners/${requestEditable.partner_id}`">{{ requestEditable.partner_id }}</router-link>
      </p>
      <p class="mt-4">
        <b>Oficina: </b>
        <router-link :to="`/oficinas/${requestEditable.office_id}`">{{ requestEditable.office_id }}</router-link>
      </p>
      <div v-if="requestEditable.status === 'REJECTED'" class="mt-4 red--text">
        <p class="mb-0"><b>Motivo:</b> {{ requestEditable.rejection_reason }}</p>
        <p><b>Comentario:</b> {{ requestEditable.rejection_comment }}</p>
      </div>
      <div class="secondary pa-4 mb-2">
        <p v-if="requestEditable.created_at" class="mt-4"><b>Fecha de creación:</b> {{ formatDateL(requestEditable.created_at) }}</p>
        <p v-if="requestEditable.revised_at" class="mt-4"><b>Fecha de revisión:</b> {{ formatDateL(requestEditable.revised_at) }}</p>
        <p v-if="requestEditable.sent_to_review_at" class="mt-4">
          <b>Fecha de envío a evaluación:</b> {{ formatDateL(requestEditable.sent_to_review_at) }}
        </p>
      </div>
      <div v-if="requestEditable.status !== 'DRAFT' && requestEditable.scoring" class="secondary pa-4">
        <p class="text-h6"><b>Scoring</b></p>
        <p class="mt-4"><b>Cómo es la prestataria:</b> {{ requestEditable.scoring.personal_recommendation }} %</p>
        <p class="mt-4"><b>Evaluación del negocio:</b> {{ requestEditable.scoring.business_evaluation }} %</p>
        <p class="mt-4"><b>Capacidad económica:</b> {{ requestEditable.scoring.economic_capacity }} %</p>
        <p class="mt-4"><b>Historial crediticio:</b> {{ requestEditable.scoring.credit_history }} %</p>
        <p class="mt-4"><b>Nivel de vida:</b> {{ requestEditable.scoring.standard_of_living }} %</p>
        <p class="mt-4"><b>Cómo es su entorno:</b> {{ requestEditable.scoring.environment }} %</p>
        <p class="mt-4"><b>Total:</b> {{ requestEditable.scoring.total }} %</p>
      </div>
    </div>

    <!-- Error list -->
    <AlertListRequiredFields v-if="$store.state.errorCode === 'INCOMPLETE-REQUEST'" />

    <!-- Form request data -->
    <v-card class="my-8 pa-1">
      <v-card-text class="pa-5">
        <v-form ref="formEditRequest" v-model="formEditRequestValid">
          <v-row>
            <!-- Borrower data -->
            <v-col cols="12">
              <p class="text-h6 mb-n3">Prestataria:</p>
            </v-col>
            <v-col cols="12" class="black--text">
              <p class="mb-1">
                <b>Nombre y apellidos: </b>
                <router-link :to="`/prestatarias/${borrower.id}`">{{ borrower.first_name }} {{ borrower.last_name }}</router-link>
              </p>
              <p class="mb-1"><b>Correo:</b> {{ borrower.email }}</p>
              <p class="mb-1"><b>Documento de identidad:</b> {{ borrower.identity_document }}</p>
              <p class="mb-1"><b>Teléfono:</b> {{ borrower.phone }}</p>
              <p v-if="borrower.borrower" class="mb-1"><b>Número de créditos:</b> {{ borrower.borrower.num_credits }}</p>
              <p class="mb-1"><b>Solicitud para el crédito Nº:</b> {{ requestEditable.credits_received + 1 }}</p>
            </v-col>

            <!-- Financial products -->
            <v-col cols="12">
              <p class="text-h6 mb-n3">
                Producto financiero:
                <span v-if="!financialProducts || financialProducts.length === 0" class="red--text">
                  no hay productos financieros habilitados para el partner de esta solicitud.
                </span>
              </p>
            </v-col>
            <v-col
              v-show="
                (requestEditable.status === 'DRAFT' && !product.disabled) ||
                (requestEditable.financial_product && requestEditable.financial_product.id === product.id)
              "
              v-for="(product, i) in financialProducts"
              :key="i"
              cols="12"
              md="3"
            >
              <div class="text-center">
                <v-btn
                  v-if="requestEditable.financial_product && requestEditable.financial_product.id != product.id"
                  x-small
                  :disabled="!editable"
                  class="mb-3"
                  @click="requestEditable.financial_product = product"
                >
                  Seleccionar
                </v-btn>
                <FinancialProduct :product="product" />
              </div>
            </v-col>

            <!-- Request form -->
            <v-col cols="12">
              <p class="text-h6 mb-n3 mt-3">Formularios de solicitud:</p>
            </v-col>
            <v-expansion-panels active-class="expansion_panel_active" v-model="panel" multiple>
              <v-col cols="12" md="6">
                <p class="text-subtitle-1 mb-0 font-weight-bold black--text">Formulario 1.0</p>
                <v-expansion-panel class="mb-3 secondary">
                  <v-expansion-panel-header>1.1 DATOS DE LA SOLICITANTE</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mb-n5 text--secondary text-caption">Nivel de estudios actualmente</p>
                    <v-select
                      v-model="requestEditable.education_level"
                      :items="form_selects_values.education_levels"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">Estado civil</p>
                    <v-select v-model="requestEditable.civil_status" :items="form_selects_values.civils_status" :readonly="!editable" />
                    <TechnicianAutocomplete
                      :listener="request"
                      :technicianId="requestEditable.technician_id"
                      :filter="{ 'country.id': borrower.country }"
                      :editable="editable"
                      @selected="requestEditable.technician_id = $event"
                    />
                    <p class="mb-n5 text--secondary text-caption">Importe solicitado</p>
                    <v-text-field
                      v-model.number="requestEditable.amount_requested"
                      type="number"
                      :rules="formRules.requestNumberRules"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">Sector del negocio</p>
                    <v-select
                      v-model="requestEditable.business_sector"
                      :items="sectors"
                      item-text="name"
                      item-value="id"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">Tipo de cliente (Rural /Urbano)</p>
                    <v-select v-model="requestEditable.customer_type" :items="form_selects_values.customer_types" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Tipo de negocio</p>
                    <v-text-field v-model="requestEditable.business_type" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Descripción del negocio</p>
                    <v-textarea v-model="requestEditable.business_description" rows="1" auto-grow :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">¿Cuál es su sueño?</p>
                    <v-textarea v-model="requestEditable.dream" rows="1" auto-grow :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Nº de niños que dependen de sus ingresos</p>
                    <v-text-field
                      v-model.number="requestEditable.number_children"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">Nº de adultos que dependen de sus ingresos</p>
                    <v-text-field
                      v-model.number="requestEditable.number_adults"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <v-checkbox
                      v-model="requestEditable.has_phone"
                      :label="requestEditable.has_phone ? '¿Tiene celular propio?: Sí' : '¿Tiene celular propio?: No'"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      ¿A qué tipo de celular tiene acceso en su día a día, aunque no sea suyo?
                    </p>
                    <v-select v-model="requestEditable.phone_type" :items="form_selects_values.phone_types" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">¿Cómo es la vivienda en la que reside?</p>
                    <v-select v-model="requestEditable.living_place" :items="form_selects_values.living_places" :readonly="!editable" />
                    <v-checkbox
                      v-model="requestEditable.own_vehicle"
                      :label="requestEditable.own_vehicle ? '¿Tiene vehículo propio?: Sí' : '¿Tiene vehículo propio?: No'"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">Nombre de quién le ha recomendado</p>
                    <v-text-field v-model="requestEditable.referred" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">¿Cuánto gasta en temas de salud?</p>
                    <v-text-field
                      v-model.number="requestEditable.health_expenses"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Cuánto gasta en mejoras en casa?</p>
                    <v-text-field
                      v-model.number="requestEditable.house_improvement_expenses"
                      label=""
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Cuánto gasta en otras cosas? Por ejemplo: ropa, diversión, libros</p>
                    <v-text-field
                      v-model.number="requestEditable.other_expenses"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Cuánto dinero ahorras al mes?</p>
                    <v-text-field v-model.number="requestEditable.money_save" :readonly="!editable" :rules="formRules.requestNumberRules" />
                    <p class="mb-n5 text--secondary text-caption">¿Quiere usted ahorrar para...?</p>
                    <v-select v-model="requestEditable.savings_target" :items="form_selects_values.savings_targets" :readonly="!editable" />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="mb-3 secondary">
                  <v-expansion-panel-header>1.2 DATOS DEL FIADOR</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mb-n5 text--secondary text-caption">Nombre</p>
                    <v-text-field v-model="requestEditable.guarantor_name" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Apellidos</p>
                    <v-text-field v-model="requestEditable.guarantor_last_name" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Numero documento identidad</p>
                    <v-text-field v-model="requestEditable.guarantor_identity_document" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Email</p>
                    <v-text-field
                      v-model="requestEditable.guarantor_email"
                      type="email"
                      :readonly="!editable"
                      :rules="formRules.requestEmailRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">Nº de teléfono</p>
                    <v-text-field v-model="requestEditable.guarantor_phone" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Dirección</p>
                    <v-textarea v-model="requestEditable.guarantor_address" rows="1" auto-grow :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Ingresos</p>
                    <v-text-field
                      v-model.number="requestEditable.guarantor_income"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="mb-3 secondary">
                  <v-expansion-panel-header>1.3 INFORMACIÓN DEL NEGOCIO</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mb-n5 text--secondary text-caption">Dirección</p>
                    <v-textarea v-model="requestEditable.business_address.name" rows="1" auto-grow :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">¿Quiere mejorar un negocio ya existente o comenzar uno nuevo?</p>
                    <v-select
                      v-model="requestEditable.business_objetive"
                      :items="form_selects_values.business_objetives"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Cuántas personas van a implicarse en el negocio?</p>
                    <v-text-field
                      v-model.number="requestEditable.business_employees"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿En qué se va a invertir el dinero principalmente?</p>
                    <v-select
                      v-model="requestEditable.investment_money"
                      :items="form_selects_values.investments_money"
                      :readonly="!editable"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="secondary">
                  <v-expansion-panel-header>1.4 QUEREMOS SABER MÁS DE USTED</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mb-n5 text--secondary text-caption">¿Tiene opción de conseguir dinero de otra manera?</p>
                    <v-select
                      v-model="requestEditable.funding_source"
                      :items="form_selects_values.funding_sources"
                      chips
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      ¿Le interesaría contratar alguno de los siguientes productos financieros?
                    </p>
                    <v-select
                      v-model="requestEditable.interested_products"
                      :items="form_selects_values.interested_products"
                      chips
                      multiple
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Con cuantas entidades tiene deuda activa sin contar con nosotros?</p>
                    <v-text-field
                      v-model.number="requestEditable.num_entities_debt"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Cómo se define a ella misma?</p>
                    <v-textarea v-model="requestEditable.definition_yourself" rows="1" auto-grow :readonly="!editable" />
                    <v-checkbox
                      v-model="requestEditable.have_bank_account"
                      :label="
                        requestEditable.have_bank_account
                          ? '¿Se va a desembolsar el crédito por transferencia bancaria?: Sí'
                          : '¿Se va a desembolsar el crédito por transferencia bancaria?: No'
                      "
                      :readonly="!editable"
                    />
                    <p v-if="requestEditable.have_bank_account" class="mb-n5 text--secondary text-caption">Nombre del banco</p>
                    <v-text-field v-if="requestEditable.have_bank_account" v-model="requestEditable.bank_name" :readonly="!editable" />
                    <p v-if="requestEditable.have_bank_account" class="mb-n5 text--secondary text-caption">Número de cuenta bancaria</p>
                    <v-text-field
                      v-if="requestEditable.have_bank_account"
                      v-model="requestEditable.num_bank_account"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      Evitar penalizaciones es lo que me motiva a pagar las cuotas de mi crédito a tiempo
                    </p>
                    <v-select
                      v-model.number="requestEditable.avoid_penalties"
                      :items="form_selects_values.know_more_answers"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      Mi sentido de responsabilidad personal es lo que me motiva a pagar las cuotas de mi crédito a tiempo
                    </p>
                    <v-select
                      v-model.number="requestEditable.personal_responsability"
                      :items="form_selects_values.know_more_answers"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      Crear nuevas oportunidades para mi negocio es lo que me motiva a pagar las cuotas de mi crédito a tiempo
                    </p>
                    <v-select
                      v-model.number="requestEditable.new_opportunities"
                      :items="form_selects_values.know_more_answers"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      La relación que tengo con los promotores de las financieras es lo que me motiva a pagar las cuotas de mi crédito a
                      tiempo
                    </p>
                    <v-select
                      v-model.number="requestEditable.promoters_relationship"
                      :items="form_selects_values.know_more_answers"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      ¿Cuál es la razón principal y más importante por la que usted paga las cuotas de su crédito a tiempo?
                    </p>
                    <v-text-field v-model="requestEditable.reason_commitment_payment" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">Si alguna vez se ha retrasado en pagar su cuota ¿cuál fue la razón?</p>
                    <v-text-field v-model="requestEditable.reason_late_payment" :readonly="!editable" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-col>

              <v-col cols="12" md="6">
                <p class="text-subtitle-1 mb-0 font-weight-bold black--text">Formulario 2.0</p>
                <v-text-field
                  v-model.number="requestEditable.business_income"
                  label="¿Actualmente cuáles son los ingresos mensuales del negocio para el que solicitas el crédito?"
                  :readonly="!editable"
                  :rules="formRules.requestNumberRules"
                />
                <v-expansion-panel class="mb-3 secondary">
                  <v-expansion-panel-header>2.1 ANÁLISIS DE HOGAR</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mb-n5 text--secondary text-caption yellow">Cuota mensual:</p>
                    <v-text-field v-model.number="requestEditable.monthly_fee" disabled />
                    <p class="mb-n5 text--secondary text-caption">
                      ¿Tiene un sueldo fijo de otro empleo o genera ingresos de otro negocio diferente al del préstamo? Indicar cantidad
                      ingresada mensualmente:
                    </p>
                    <v-text-field
                      v-model.number="requestEditable.other_business_income"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      Indicar ingresos de familiares que tienen un sueldo fijo de otro empleo o genera ingresos de otro negocio diferente al
                      del préstamo:
                    </p>
                    <v-text-field
                      v-model.number="requestEditable.income_another_family"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">Renta, luz, agua...</p>
                    <v-text-field
                      v-model.number="requestEditable.house_expenses"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">Alimentacion familiar</p>
                    <v-text-field
                      v-model.number="requestEditable.food_expenses"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">Educación</p>
                    <v-text-field
                      v-model.number="requestEditable.education_expenses"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">Otros gastos mensuales</p>
                    <v-text-field
                      v-model.number="requestEditable.other_monthly_expenses"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption yellow">Capacidad de pago si el negocio va mal</p>
                    <v-text-field v-model.number="requestEditable.payment_capacity_if_wrong" suffix="%" disabled />
                    <ul>
                      <li class="font-weight-bold">
                        Total ingresos hogar:
                        {{ Number(requestEditable.other_business_income) + Number(requestEditable.income_another_family) }}
                      </li>
                      <li class="font-weight-bold">
                        Total gastos hogar:
                        {{
                          Number(requestEditable.house_expenses) +
                          Number(requestEditable.food_expenses) +
                          Number(requestEditable.education_expenses) +
                          Number(requestEditable.other_monthly_expenses)
                        }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="mb-3 secondary">
                  <v-expansion-panel-header>2.2 ANÁLISIS DE NEGOCIO</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mb-n5 text--secondary text-caption">Tipo de productos</p>
                    <v-select v-model="requestEditable.product_type" :items="form_selects_values.product_types" :readonly="!editable" />
                    <div v-if="requestEditable.product_type === 'UNIT_PRODUCTS'">
                      <div v-for="(product, i) in requestEditable.unit_products" :key="i" class="white pa-5 mb-2">
                        <v-toolbar dense flat>
                          <v-toolbar-title>Producto {{ i + 1 }}</v-toolbar-title>
                          <v-spacer />
                          <v-btn v-if="i != 0 && editable" icon elevation="2" small color="red" @click="removeUnitProduct(i)">
                            <v-icon small>delete</v-icon>
                          </v-btn>
                        </v-toolbar>
                        <p class="mb-n5 text--secondary text-caption">Nombre del producto</p>
                        <v-text-field v-model="product.name" :readonly="!editable" :rules="formRules.textRules" />
                        <p class="mb-n5 text--secondary text-caption">¿Cuántos compra?</p>
                        <v-text-field
                          v-model.number="product.purchase_units"
                          :readonly="!editable"
                          :rules="formRules.numberRulesAllowZero"
                        />
                        <p class="mb-n5 text--secondary text-caption">A cuánto compra?</p>
                        <v-text-field
                          v-model.number="product.purchase_price"
                          :readonly="!editable"
                          :rules="formRules.numberRulesAllowZero"
                        />
                        <p class="mb-n5 text--secondary text-caption">¿Cuántos vende?</p>
                        <v-text-field v-model.number="product.sale_units" :readonly="!editable" :rules="formRules.numberRulesAllowZero" />
                        <p class="mb-n5 text--secondary text-caption">¿A cuánto vende?</p>
                        <v-text-field v-model.number="product.sale_price" :readonly="!editable" :rules="formRules.numberRulesAllowZero" />
                      </div>
                      <div v-if="editable" class="text-right">
                        <v-btn elevation="2" small @click="addUnitProduct">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </div>
                    </div>

                    <div v-if="requestEditable.product_type === 'SERVICES_OR_VARIOUS_PRODUCTS'">
                      <p class="mb-n5 text--secondary text-caption">Especifica qué tipo de mercadería vende o qué servicio ofrece</p>
                      <v-textarea
                        v-model="requestEditable.service.type"
                        rows="1"
                        auto-grow
                        :readonly="!editable"
                        :rules="formRules.textRules"
                      />
                      <p class="mb-n5 text--secondary text-caption">
                        Dinero aproximado que se gasta mensualmente en abastecer su negocio (compra de mercadería, etc)
                      </p>
                      <v-text-field
                        v-model.number="requestEditable.service.expenses"
                        :readonly="!editable"
                        :rules="formRules.numberRulesAllowZero"
                      />
                      <p class="mb-n5 text--secondary text-caption">
                        Ingresos mensuales aproximados obtenidos gracias a la venta de productos y servicios
                      </p>
                      <v-text-field
                        v-model.number="requestEditable.service.income"
                        :readonly="!editable"
                        :rules="formRules.numberRulesAllowZero"
                      />
                    </div>

                    <p class="mb-n5 text--secondary text-caption">Costes de consumibles</p>
                    <v-text-field
                      v-model.number="requestEditable.consumable_costs"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption yellow">Costes operativos totales</p>
                    <v-text-field v-model.number="requestEditable.total_operating_costs" disabled />
                    <p class="mb-n5 text--secondary text-caption">Si tiene empleados en el negocio, ¿cuánto gasta al mes pagándoles?</p>
                    <v-text-field
                      v-model.number="requestEditable.employee_expenses"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      Costes de compra/alquiler de maquinaria o animales (motos, coches, máquinas, vacas, cerdos…)
                    </p>
                    <v-text-field
                      v-model.number="requestEditable.machinery_costs"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">Coste alquiler</p>
                    <v-text-field
                      v-model.number="requestEditable.rental_costs"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">Cuotas de otras entidades (mensual)</p>
                    <v-text-field
                      v-model.number="requestEditable.fees_other_entities"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <ul class="mb-3">
                      <li class="font-weight-bold">Total ingresos negocio: {{ Number(getTotalBusinessIcome()) }}</li>
                      <li class="font-weight-bold">Total gastos negocio: {{ Number(getTotalBusinessExpenses()) }}</li>
                    </ul>
                    <p class="mb-n5 text--secondary text-caption yellow">Beneficios del negocio</p>
                    <v-text-field v-model.number="requestEditable.total_business_income" disabled />
                    <ul class="mb-3">
                      <li class="font-weight-bold">
                        Total ingresos (hogar y negocio):
                        {{
                          Number(requestEditable.other_business_income) +
                          Number(requestEditable.income_another_family) +
                          Number(getTotalBusinessIcome())
                        }}
                      </li>
                      <li class="font-weight-bold">
                        Total gastos (hogar y negocio):
                        {{
                          Number(requestEditable.house_expenses) +
                          Number(requestEditable.food_expenses) +
                          Number(requestEditable.education_expenses) +
                          Number(requestEditable.other_monthly_expenses) +
                          Number(getTotalBusinessExpenses())
                        }}
                      </li>
                    </ul>
                    <p class="mb-n5 text--secondary text-caption yellow">Beneficios totales de la familia (hogar y negocio)</p>
                    <v-text-field v-model.number="requestEditable.total_family_income" disabled />
                    <p class="mb-n5 text--secondary text-caption yellow">Capacidad de pago si el negocio va bien</p>
                    <v-text-field v-model.number="requestEditable.payment_capacity_if_well" suffix="%" disabled />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <p class="text-subtitle-1 mb-0 font-weight-bold black--text">Formulario 3.0</p>
                <v-expansion-panel class="secondary">
                  <v-expansion-panel-header>3.1 SCORING</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="mb-n5 text--secondary text-caption">
                      Cantidad total que debe a otras entidades según los registros oficiales
                    </p>
                    <v-text-field
                      v-model.number="requestEditable.total_debt_other_entities"
                      :readonly="!editable"
                      :rules="formRules.requestNumberRules"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Tuvo algún retraso en anteriores deudas?</p>
                    <v-select v-model="requestEditable.payment_delays" :items="form_selects_values.payments_delays" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption">¿Ella o su familia tienen empleo actualmente?</p>
                    <v-select v-model="requestEditable.have_job" :items="form_selects_values.have_job" :readonly="!editable" />
                    <p class="mb-n5 text--secondary text-caption yellow">
                      Capacidad de pago: ¿Cuánto puede pagar la mujer, un mes normal, sin tener en cuenta el incremento de ingresos que va a
                      tener con el negocio?
                    </p>
                    <v-select
                      v-model.number="requestEditable.payment_capacity_without_income"
                      :items="form_selects_values.payment_capacity_answers"
                      disabled
                    />
                    <p class="mb-n5 text--secondary text-caption yellow">
                      Capacidad de pago: Incluyendo la previsión del negocio en el que se va a invertir, ¿cuánto dinero va a tener la mujer
                      al mes para pagar las cuotas del préstamo?
                    </p>
                    <v-select
                      v-model.number="requestEditable.payment_capacity"
                      :items="form_selects_values.payment_capacity_answers"
                      disabled
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Conoce los ingresos que va a tener su negocio?</p>
                    <v-select
                      v-model.number="requestEditable.know_business_income"
                      :items="form_selects_values.know_business_answers"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Conoce los gastos que va a tener su negocio?</p>
                    <v-select
                      v-model.number="requestEditable.know_business_expenses"
                      :items="form_selects_values.know_business_answers"
                      :readonly="!editable"
                    />
                    <v-checkbox
                      v-model="requestEditable.know_business"
                      :label="
                        requestEditable.know_business
                          ? '¿Conoce el negocio que va a montar/ampliar?: Sí'
                          : '¿Conoce el negocio que va a montar/ampliar?: No'
                      "
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Lleva un control de las cuentas de su negocio?</p>
                    <v-select
                      v-model="requestEditable.account_control"
                      :items="form_selects_values.accounts_control"
                      :readonly="!editable"
                    />
                    <v-checkbox
                      v-model="requestEditable.transmits_confidence"
                      :label="requestEditable.transmits_confidence ? '¿Transmite confianza?: Sí' : '¿Transmite confianza?: No'"
                      :readonly="!editable"
                    />
                    <v-checkbox
                      v-model="requestEditable.perceive_problems"
                      :label="
                        requestEditable.perceive_problems
                          ? '¿Percibes que vamos a tener algún tipo de problema con esta mujer?: Sí'
                          : '¿Percibes que vamos a tener algún tipo de problema con esta mujer?: No'
                      "
                      :readonly="!editable"
                    />
                    <v-checkbox
                      v-model="requestEditable.business_visit"
                      :label="
                        requestEditable.business_visit
                          ? '¿Has ido a visitar el negocio o el lugar donde se ubicará?: Sí'
                          : '¿Has ido a visitar el negocio o el lugar donde se ubicará?: No'
                      "
                      :readonly="!editable"
                    />
                    <v-checkbox
                      v-model="requestEditable.realistic_business"
                      :label="
                        requestEditable.realistic_business
                          ? 'Cuando has estado allí ¿te ha parecido que el negocio de la mujer o el que quiere montar es realista?: Sí'
                          : 'Cuando has estado allí ¿te ha parecido que el negocio de la mujer o el que quiere montar es realista?: No'
                      "
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">
                      ¿Qué opinión tiene el líder de la comunidad u otro referente sobre la prestataria?
                    </p>
                    <v-select
                      v-model.number="requestEditable.opinion_borrower"
                      :items="form_selects_values.opinions_borrower"
                      :readonly="!editable"
                    />
                    <p class="mb-n5 text--secondary text-caption">¿Hay alguna otra persona que haya recomendado a esta mujer?</p>
                    <v-select
                      v-model.number="requestEditable.recommended_borrower"
                      :items="form_selects_values.recommended_borrower"
                      :readonly="!editable"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-col>
            </v-expansion-panels>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="requestEditable.status === 'DRAFT'" class="justify-end">
        <v-icon v-if="!editable && permissionsCardsActions.requestData.edit.includes(user.role)" @click="editable = true">edit</v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- IMAGES -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>Imágenes</v-toolbar>
      <v-card-text class="pa-5">
        <v-row>
          <v-col v-show="isPartnerFromMX" cols="12" md="6">
            <RequestImage
              :title="'Imagen de la cuenta bancaria'"
              :image_path="requestEditable.image_bank_account"
              :variable_name="'image_bank_account'"
              :request_id="requestEditable.id"
            />
          </v-col>

          <v-col cols="12" md="6">
            <RequestImage
              :title="'Imagen de la central de riesgos'"
              :image_path="requestEditable.image_risk_center"
              :variable_name="'image_risk_center'"
              :request_id="requestEditable.id"
            />
          </v-col>

          <v-col cols="12" md="6">
            <RequestImage
              :title="'Documento de identidad ANVERSO'"
              :image_path="requestEditable.image_identity_document_front"
              :variable_name="'image_identity_document_front'"
              :request_id="requestEditable.id"
            />
          </v-col>

          <v-col cols="12" md="6">
            <RequestImage
              :title="'Documento de identidad REVERSO'"
              :image_path="requestEditable.image_identity_document_back"
              :variable_name="'image_identity_document_back'"
              :request_id="requestEditable.id"
            />
          </v-col>

          <v-col cols="12" md="6">
            <RequestImage
              :title="'Documento de identidad FIADOR'"
              :image_path="requestEditable.image_identity_document_guarantor"
              :variable_name="'image_identity_document_guarantor'"
              :request_id="requestEditable.id"
            />
          </v-col>

          <v-col cols="12" md="6">
            <RequestImage
              :title="'Imagen del negocio'"
              :image_path="requestEditable.image_business"
              :variable_name="'image_business'"
              :request_id="requestEditable.id"
            />
          </v-col>

          <v-col cols="12" md="6">
            <RequestImage
              :title="'Imagen de comprobante de domicilio'"
              :image_path="requestEditable.image_invoice"
              :variable_name="'image_invoice'"
              :request_id="requestEditable.id"
            />
          </v-col>

          <v-col v-show="isPartnerFromMX" cols="12" md="6">
            <RequestImage
              :title="'Imagen círculo de autorización del crédito'"
              :image_path="requestEditable.image_credit_circle_authorization"
              :variable_name="'image_credit_circle_authorization'"
              :request_id="requestEditable.id"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialog delete request -->
    <DialogBox :color="'error'" :model="dialogDeleteRequest" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de eliminar esta solicitud?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogDeleteRequest = false">Cerrar</v-btn>
        <v-btn text @click="(dialogDeleteRequest = false), actionDeleteRequest()">eliminar</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import listeners from "@/firebase/listeners";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import _ from "lodash";
import Banner from "@/components/elements/Banner";
import DialogBox from "@/components/elements/DialogBox";
import BtnRejectRequest from "@/components/elements/request/BtnRejectRequest.vue";
import RequestImage from "@/components/elements/request/RequestImage.vue";
import FinancialProduct from "@/components/elements/products/FinancialProduct.vue";
import TechnicianAutocomplete from "@/components/elements/technician/TechnicianAutocomplete";
import * as currency from "currency.js";
import AlertListRequiredFields from "@/components/elements/request/AlertListRequiredFields.vue";

export default {
  name: "Request",
  components: {
    Banner,
    DialogBox,
    BtnRejectRequest,
    RequestImage,
    FinancialProduct,
    TechnicianAutocomplete,
    AlertListRequiredFields
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  data() {
    return {
      panel: [0],
      // Banner actions permissions
      permissionsBannerActions: {
        delete: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"],
        downloadPdf: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"],
        sendRequest: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"],
        approveRequest: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"],
        rejectRequest: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
      },

      // Cards actions permissions
      permissionsCardsActions: {
        requestData: {
          edit: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"]
        }
      },

      // Form edit request
      editable: false,
      formEditRequestValid: false,
      requestEditable: {
        civil_status: "",
        amount_requested: 0,
        business_sector: "",
        customer_type: "",
        business_type: "",
        business_description: "",
        dream: "",
        number_children: "",
        number_adults: "",
        has_phone: false,
        phone_type: "",
        living_place: "",
        own_vehicle: false,
        referred: "",
        health_expenses: "",
        house_improvement_expenses: "",
        other_expenses: "",
        money_save: "",
        savings_target: "",
        guarantor_name: "",
        guarantor_last_name: "",
        guarantor_identity_document: "",
        guarantor_email: "",
        guarantor_phone: "",
        guarantor_address: "",
        guarantor_income: "",
        business_address: {
          coordinates: {
            lat: 0,
            lon: 0
          },
          name: ""
        },
        business_objetive: "",
        business_employees: "",
        investment_money: "",
        funding_source: [],
        interested_products: [],
        num_entities_debt: "",
        definition_yourself: "",
        have_bank_account: false,
        bank_name: "",
        num_bank_account: "",
        avoid_penalties: "",
        personal_responsability: "",
        new_opportunities: "",
        promoters_relationship: "",
        reason_commitment_payment: "",
        reason_late_payment: "",
        monthly_fee: "",
        business_income: "",
        other_business_income: "",
        income_another_family: "",
        house_expenses: "",
        food_expenses: "",
        education_expenses: "",
        other_monthly_expenses: "",
        product_type: "",
        consumable_costs: "",
        employee_expenses: "",
        machinery_costs: "",
        rental_costs: "",
        fees_other_entities: "",
        total_debt_other_entities: "",
        payment_delays: "",
        have_job: "",
        payment_capacity_without_income: "",
        payment_capacity: "",
        know_business_income: "",
        know_business_expenses: "",
        know_business: false,
        account_control: "",
        transmits_confidence: false,
        perceive_problems: false,
        business_visit: false,
        realistic_business: false,
        opinion_borrower: "",
        recommended_borrower: "",
        image_bank_account: "",
        image_risk_center: "",
        image_identity_document_front: "",
        image_identity_document_back: "",
        image_identity_document_guarantor: "",
        image_business: "",
        image_invoice: "",
        image_credit_circle_authorization: "",
        unit_products: [],
        service: {
          type: "",
          expenses: "",
          income: ""
        },
        financial_product: {}
      },

      // Dialogs
      dialogDeleteRequest: false
    };
  },
  computed: {
    ...mapState({
      request: (state) => state.requests.request,
      financialProducts: (state) => state.products.financialProducts,
      borrower: (state) => state.borrowers.borrower,
      user: (state) => state.user.user,
      sectors: (state) => state.sectors.sectors,
      partners: (state) => state.partners.partners,
      form_selects_values: (state) => state.requests.form_selects_values
    }),
    _() {
      return _;
    },
    isPartnerFromMX() {
      return this.partners.find((e) => e.id === this.requestEditable.partner_id)?.country.id === "MX";
    }
  },
  watch: {
    request() {
      this.formatFields();
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await listeners.request(this.$route.params.id);
    // Push back if id does not exist
    if (
      this._.isEmpty(this.request) ||
      this.request.id !== this.$route.params.id ||
      (this.user.role === "TECHNICIAN" && this.request.technician_id !== this.user.user_id)
    ) {
      this.$router.push("/solicitudes");
      this.$store.commit("SET_LOADING", false);
      return;
    }
    await listeners.borrower(this.request.borrower_id);
    await this.$store.dispatch("sectors/getSectors");
    await this.$store.dispatch("partners/getPartners");
    await this.formatFields();
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    ...mapActions({
      approveRequest: "requests/approveRequest"
    }),

    // Format fields editables
    async formatFields() {
      this.requestEditable = { ...this.requestEditable, ...this.request };
      this.requestEditable.amount_requested = currency(this.requestEditable.amount_requested, { fromCents: true });

      if (!this.request.unit_products) {
        this.requestEditable.unit_products = [
          {
            name: "",
            purchase_units: "",
            purchase_price: "",
            sale_units: "",
            sale_price: ""
          }
        ];
      }

      await this.$store.dispatch("products/getFinancialProducts", {
        filter: {
          "requirements.offices": this.request.office_id,
          range: {
            "requirements.min_credits": {
              lte: this.request.credits_received
            }
          }
        }
      });
    },

    // Edit request
    async edit() {
      if (this.$refs.formEditRequest.validate()) {
        let requestEditableFormattedData = {
          id: this.requestEditable.id,
          technician_id: this.requestEditable.technician_id,
          credits_received: this.requestEditable.credits_received,
          civil_status: this.requestEditable.civil_status,
          amount_requested: this.requestEditable.amount_requested,
          business_sector: this.requestEditable.business_sector,
          customer_type: this.requestEditable.customer_type,
          business_type: this.requestEditable.business_type,
          business_description: this.requestEditable.business_description,
          dream: this.requestEditable.dream,
          number_children: this.requestEditable.number_children,
          number_adults: this.requestEditable.number_adults,
          has_phone: this.requestEditable.has_phone,
          phone_type: this.requestEditable.phone_type,
          living_place: this.requestEditable.living_place,
          own_vehicle: this.requestEditable.own_vehicle,
          referred: this.requestEditable.referred,
          health_expenses: this.requestEditable.health_expenses,
          house_improvement_expenses: this.requestEditable.house_improvement_expenses,
          other_expenses: this.requestEditable.other_expenses,
          money_save: this.requestEditable.money_save,
          savings_target: this.requestEditable.savings_target,
          guarantor_name: this.requestEditable.guarantor_name,
          guarantor_last_name: this.requestEditable.guarantor_last_name,
          guarantor_identity_document: this.requestEditable.guarantor_identity_document,
          guarantor_email: this.requestEditable.guarantor_email,
          guarantor_phone: this.requestEditable.guarantor_phone,
          guarantor_address: this.requestEditable.guarantor_address,
          guarantor_income: this.requestEditable.guarantor_income,
          business_address: this.requestEditable.business_address,
          business_objetive: this.requestEditable.business_objetive,
          business_employees: this.requestEditable.business_employees,
          investment_money: this.requestEditable.investment_money,
          funding_source: this.requestEditable.funding_source,
          interested_products: this.requestEditable.interested_products,
          num_entities_debt: this.requestEditable.num_entities_debt,
          definition_yourself: this.requestEditable.definition_yourself,
          have_bank_account: this.requestEditable.have_bank_account,
          bank_name: this.requestEditable.bank_name,
          num_bank_account: this.requestEditable.num_bank_account,
          avoid_penalties: this.requestEditable.avoid_penalties,
          personal_responsability: this.requestEditable.personal_responsability,
          new_opportunities: this.requestEditable.new_opportunities,
          promoters_relationship: this.requestEditable.promoters_relationship,
          reason_commitment_payment: this.requestEditable.reason_commitment_payment,
          reason_late_payment: this.requestEditable.reason_late_payment,
          business_income: this.requestEditable.business_income,
          other_business_income: this.requestEditable.other_business_income,
          income_another_family: this.requestEditable.income_another_family,
          house_expenses: this.requestEditable.house_expenses,
          food_expenses: this.requestEditable.food_expenses,
          education_expenses: this.requestEditable.education_expenses,
          education_level: this.requestEditable.education_level,
          other_monthly_expenses: this.requestEditable.other_monthly_expenses,
          product_type: this.requestEditable.product_type,
          unit_products: this.requestEditable.unit_products,
          service: this.requestEditable.service,
          consumable_costs: this.requestEditable.consumable_costs,
          employee_expenses: this.requestEditable.employee_expenses,
          machinery_costs: this.requestEditable.machinery_costs,
          rental_costs: this.requestEditable.rental_costs,
          fees_other_entities: this.requestEditable.fees_other_entities,
          total_debt_other_entities: this.requestEditable.total_debt_other_entities,
          payment_delays: this.requestEditable.payment_delays,
          have_job: this.requestEditable.have_job,
          know_business_income: this.requestEditable.know_business_income,
          know_business_expenses: this.requestEditable.know_business_expenses,
          know_business: this.requestEditable.know_business,
          account_control: this.requestEditable.account_control,
          transmits_confidence: this.requestEditable.transmits_confidence,
          perceive_problems: this.requestEditable.perceive_problems,
          business_visit: this.requestEditable.business_visit,
          realistic_business: this.requestEditable.realistic_business,
          opinion_borrower: this.requestEditable.opinion_borrower,
          recommended_borrower: this.requestEditable.recommended_borrower,
          financial_product: this.requestEditable?.financial_product.id
        };
        requestEditableFormattedData.amount_requested = this.convertToInt(requestEditableFormattedData.amount_requested);

        // Delete parameter unit_products if product_type is "SERVICES_OR_VARIOUS_PRODUCTS"
        if (requestEditableFormattedData.product_type === "SERVICES_OR_VARIOUS_PRODUCTS") delete requestEditableFormattedData.unit_products;
        // Delete parameter service if product_type is "UNIT_PRODUCTS"
        else if (requestEditableFormattedData.product_type === "UNIT_PRODUCTS") delete requestEditableFormattedData.service;

        // Delete parameter business_address if business_address.name is empty"
        if (!requestEditableFormattedData.business_address.name) delete requestEditableFormattedData.business_address;

        // Check if there is no changes
        console.log(this.cleanObject(requestEditableFormattedData));
        await this.$store.dispatch("requests/editRequest", this.cleanObject(requestEditableFormattedData));

        await this.formatFields();
        this.editable = false;
      }
    },

    // Add a product to array of unit_products
    addUnitProduct() {
      this.requestEditable.unit_products.push({
        name: "",
        purchase_units: "",
        purchase_price: "",
        sale_units: "",
        sale_price: ""
      });
    },

    // Remove a product from array of unit_products
    removeUnitProduct(index) {
      this.requestEditable.unit_products.splice(index, 1);
    },

    // Get total business income
    getTotalBusinessIcome() {
      if (this.requestEditable.product_type === "UNIT_PRODUCTS") {
        let total = 0;

        for (let i = 0; i < this.requestEditable.unit_products.length; i++) {
          const product = this.requestEditable.unit_products[i];
          total += product.sale_units * product.sale_price;
        }

        return total;
      } else return this.requestEditable.service.income;
    },

    // Get total business expenses
    getTotalBusinessExpenses() {
      if (this.requestEditable.product_type === "UNIT_PRODUCTS") {
        let total = 0;

        for (let i = 0; i < this.requestEditable.unit_products.length; i++) {
          const product = this.requestEditable.unit_products[i];
          total += product.purchase_units * product.purchase_price;
        }

        return total;
      } else return this.requestEditable.service.expenses;
    },

    async actionDeleteRequest() {
      await this.$store.dispatch("requests/deleteRequest", this.requestEditable.id);
      this.$router.push("/requests");
      this.dialogDeleteRequest = false;
    }
  }
};
</script>

<style scoped>
.v-expansion-panel .v-expansion-panel-header,
.v-expansion-panel .v-expansion-panel-content {
  background-color: #d4d4d4;
}
.v-expansion-panel--active .v-expansion-panel-header,
.v-expansion-panel--active .v-expansion-panel-content {
  background-color: #f5f5f5;
}
.selected-product {
  border: 2px solid #00f9ad;
}
</style>
