<template>
  <div class="secondary py-10">
    <p class="text-center">{{ title }}</p>
    <LazyImage :img="imageUrl ? imageUrl : ''" :lazy="lazyImage" />
    <v-form
      v-if="permissions.uploadImage.includes(user.role)"
      ref="formUploadImage"
      v-model="formUploadImageValid"
      class="text-center px-10"
    >
      <v-file-input
        v-model="image"
        :accept="['.jpeg', '.png', '.jpg']"
        truncate-length="100"
        :clearable="false"
        prepend-icon="image"
        label="Selecciona una imagen"
        :rules="formRules.imageRequiredRules"
      />
      <v-btn @click="uploadImage()">Guardar</v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import LazyImage from "@/components/elements/LazyImage";

export default {
  components: {
    LazyImage
  },
  props: {
    title: {
      type: String,
      requied: true
    },
    image_path: {
      type: String,
      requied: true
    },
    variable_name: {
      type: String,
      requied: true
    },
    request_id: {
      type: String,
      requied: true
    }
  },
  mixins: [CommonMixin, FormRulesMixin],
  data() {
    return {
      formUploadImageValid: false,
      image: null,
      imageUrl: null,
      lazyImage: false,

      permissions: {
        uploadImage: ["SUPERADMIN", "ADMIN", "TECHNICIAN"]
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    })
  },
  async created() {
    if (this.image_path !== "") this.imageUrl = await this.getFileFromStorage(this.image_path);
  },
  watch: {
    async image_path() {
      if (this.image_path !== "") this.imageUrl = await this.getFileFromStorage(this.image_path);
    }
  },
  methods: {
    // Valid and upload images
    async uploadImage() {
      if (this.$refs.formUploadImage.validate()) {
        const file = await this.toBase64(this.image);

        await this.$store.dispatch("requests/addRequestImage", {
          request_id: this.request_id,
          image: this.variable_name,
          file
        });

        this.lazyImage = true;
        this.image = null;
        this.$refs.formUploadImage.resetValidation();
      }
    }
  }
};
</script>
