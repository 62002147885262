<template>
  <div class="red lighten-3 pa-5">
    <p class="text-h6">Los siguientes campos son requeridos para poder enviar la solicitud:</p>

    <ul>
      <li v-show="errorListTranslation[error]" v-for="error of $store.state.errorList" :key="error">
        {{ errorListTranslation[error] }}
      </li>
    </ul>

    <div class="text-right">
      <v-btn @click="$store.commit('SET_ERROR_CODE', '')">Cerrar</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorListTranslation: {
        civil_status: "Estado civil",
        amount_requested: "Importe solicitado",
        business_sector: "Sector del negocio",
        customer_type: "Tipo de cliente",
        business_type: "Tipo de negocio",
        business_description: "Descripción del negocio",
        dream: "¿Cuál es su sueño?",
        number_children: "Nº de niños que dependen de sus ingresos",
        number_adults: "Nº de adultos que dependen de sus ingresos",
        has_phone: "¿Tiene celular propio?",
        phone_type: "Tipo de celular",
        living_place: "Vivienda en la que reside",
        own_vehicle: "¿Tiene vehículo propio?",
        referred: "Nombre de quien la ha recomendado",
        health_expenses: "Gastos en salud",
        house_improvement_expenses: "¿Cuánto gasta en mejoras en casa?",
        other_expenses: "¿Cuánto gasta en otras cosas?",
        money_save: "¿Cuánto dinero ahorra al mes?",
        savings_target: "¿Quiere usted ahorrar para...?",
        guarantor_name: "Nombre del fiador",
        guarantor_last_name: "Apellido del fiador",
        guarantor_identity_document: "Documento de identidad del fiador",
        guarantor_email: "Email del fiador",
        guarantor_phone: "Teléfono del fiador",
        guarantor_address: "Dirección del fiador",
        guarantor_income: "Ingresos del fiador",
        business_address: "Dirección del negocio",
        business_objetive: "Objetivo del negocio",
        business_employees: "Empleados del negocio",
        investment_money: "¿En qué se va a invertir el dinero principalmente?",
        funding_source: "¿Tiene opción de conseguir dinero de otra manera?",
        interested_products: "¿Le interesaría contratar alguno de los siguientes productos financieros?",
        num_entities_debt: "¿Con cuantas entidades tiene deuda activa sin contar con nosotros?",
        definition_yourself: "¿Cómo se define a ella misma?",
        have_bank_account: "¿Se va a desembolsar el crédito por transferencia bancaria?",
        bank_name: "Nombre del banco",
        num_bank_account: "número de cuenta bancaria",
        avoid_penalties: "Evitar penalizaciones es lo que me motiva a pagar las cuotas de mi crédito a tiempo",
        personal_responsability: "Mi sentido de responsabilidad personal es lo que me motiva a pagar las cuotas de mi crédito a tiempo",
        new_opportunities: "Crear nuevas oportunidades para mi negocio es lo que me motiva a pagar las cuotas de mi crédito a tiempo",
        promoters_relationship:
          " La relación que tengo con los promotores de las financieras es lo que me motiva a pagar las cuotas de mi crédito a tiempo",
        reason_commitment_payment: "¿Cuál es la razón principal y más importante por la que usted paga las cuotas de su crédito a tiempo?",
        reason_late_payment: "Si alguna vez se ha retrasado en pagar su cuota ¿cuál fue la razón?",
        monthly_fee: "Cuota mensual",
        business_income: "¿Actualmente cuáles son los ingresos mensuales del negocio para el que solicitas el crédito?",
        other_business_income: "¿Tiene un sueldo fijo de otro empleo o genera ingresos de otro negocio diferente al del préstamo?",
        income_another_family:
          "Indicar ingresos de familiares que tienen un sueldo fijo de otro empleo o genera ingresos de otro negocio diferente al del préstamo",
        house_expenses: "Gastos en renta, luz, agua...",
        food_expenses: "Gastos en alimentación familiar",
        education_expenses: "Gastos en educación",
        other_monthly_expenses: "Otros gastos mensuales",
        product_type: "Tipo de productos",
        consumable_costs: "Costes de consumibles",
        employee_expenses: "Si tiene empleados en el negocio, ¿cuánto gasta al mes pagándoles?",
        machinery_costs: "Costes de compra/alquiler de maquinaria o animales (motos, coches, máquinas, vacas, cerdos…)",
        rental_costs: "Coste alquiler",
        fees_other_entities: "Cuotas de otras entidades (mensual)",
        total_debt_other_entities: "Cantidad total que debe a otras entidades según los registros oficiales",
        payment_delays: "¿Tuvo algún retraso en anteriores deudas?",
        have_job: "¿Ella o su familia tienen empleo actualmente?",
        payment_capacity_without_income:
          "Capacidad de pago: ¿Cuánto puede pagar la mujer, un mes normal, sin tener en cuenta el incremento de ingresos que va a  tener con el negocio?",
        payment_capacity:
          "Capacidad de pago: Incluyendo la previsión del negocio en el que se va a invertir, ¿cuánto dinero va a tener la mujer al mes para pagar las cuotas del préstamo?",
        know_business_income: "¿Conoce los ingresos que va a tener su negocio?",
        know_business_expenses: "¿Conoce los gastos que va a tener su negocio?",
        know_business: "¿Conoce el negocio que va a montar/ampliar?",
        account_control: "¿Lleva un control de las cuentas de su negocio?",
        transmits_confidence: "¿Transmite confianza?",
        perceive_problems: "¿Percibes que vamos a tener algún tipo de problema con esta mujer?",
        business_visit: "¿Has ido a visitar el negocio o el lugar donde se ubicará?",
        realistic_business: "Cuando has estado allí ¿te ha parecido que el negocio de la mujer o el que quiere montar es realista?",
        opinion_borrower: "¿Qué opinión tiene el líder de la comunidad u otro referente sobre la prestataria?",
        recommended_borrower: "¿Hay alguna otra persona que haya recomendado a esta mujer?",
        image_bank_account: "Falta imagen de la cuenta bancaria",
        image_risk_center: "Falta imagen de la central de riesgos",
        image_identity_document_front: "Falta imagen del documento de identidad anverso",
        image_identity_document_back: "Falta imagen del documento de identidad reverso",
        image_identity_document_guarantor: "Falta imagen del documento de identidad del fiador",
        image_business: "Falta imagen del negocio",
        image_invoice: "Falta imagen de comprobante de domicilio",
        image_credit_circle_authorization: "Imagen círculo de autorización del crédito",
        unit_products: "Productos unitarios",
        service: "Especifica qué tipo de mercadería vende o qué servicio ofrece",
        financial_product: "Elige un producto financiero"
      }
    };
  }
};
</script>
